<template>
  <v-container id="grid" fluid grid-list-sm tag="section" v-if="!loading">
    <v-container v-if="!profile"> <h3>Not found</h3> </v-container>
    <v-container v-else>
      <v-row>
        <v-col cols="12" sm="10">
          <user-profile :profile="profile" />
        </v-col>
      </v-row>
    </v-container>
    <v-btn color="primary" @click="$router.go(-1)" icon>
      <v-icon>chevron_left</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
  import { mapGetters } from "vuex";
  import UserProfile from "./UserProfile";
  export default {
    name: "PublicProfile",
    components: { UserProfile },
    data() {
      return {
        id: null,
        tab: null
      };
    },
    mounted() {
      let id = this.$route.params.id;

      if (
        this.$route.path.startsWith("/tag") ||
        this.$route.path.startsWith("/producer")
      ) {
        this.$store.dispatch("loadUserProfileByHandle", { tag: id });
      } else {
        this.id = id;
        this.$store.dispatch("loadUserProfile", { uid: id });
      }
    },
    computed: {
      ...mapGetters({
        profile: "userProfile",
        user: "user",
        loading: "loading"
      }),
      justCity() {
        return this.profileDefaultAddress
          ? `${this.profileDefaultAddress.city || ""} ${this
              .profileDefaultAddress.state || ""} `
          : null;
      }
    },
    methods: {
      gotoAddress() {
        console.log("goto");
      }
    }
  };
</script>
<style scoped>
  a {
    text-decoration: none;
  }
  .rounded-circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }
</style>
